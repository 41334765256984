export default [
  {
    path: '/login',
    name: 'staff-login',
    component: () => import('@/views/staff/StaffLogin.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/',
    name: 'staff-dashboard',
    component: () => import('@/views/staff/Dashboard.vue'),
    meta: {
      action: 'read',
      resource: 'Dashboard',
      breadcrumb: [
        {
          text: 'หน้าแรก"',
          active: true,
        },
      ],
    },
  },
  {
    path: '/novel/high-possibility-novel-fill-form',
    name: 'staff-high-possibility-novel-fill-form',
    component: () => import('@/views/staff/novel/HighPossibilityFillForm.vue'),
    meta: {
      action: 'manage',
      resource: 'Novel',
      breadcrumb: [
        {
          text: 'บันทึกเสี่ยงสูง "รายใหม่"',
          active: true,
        },
      ],
    },
  },
  {
    path: '/novel/list',
    name: 'staff-novel-list',
    component: () => import('@/views/staff/novel/NovelList.vue'),
    meta: {
      action: 'manage',
      resource: 'Novel',
    },
  },
  {
    path: '/novel/detail/create-new',
    name: 'staff-novel-full-form-create-new',
    component: () => import('@/views/staff/novel/NovelFullForm.vue'),
    meta: {
      action: 'manage',
      resource: 'Novel',
      breadcrumb: [
        {
          text: 'รายการบันทึก',
          to: { name: 'staff-novel-list' },
        },
        {
          text: 'สร้างรายการใหม่',
          active: true,
        },
      ],
    },
  },
  {
    path: '/novel/detail/:novelId',
    name: 'staff-novel-full-form',
    component: () => import('@/views/staff/novel/NovelFullForm.vue'),
    meta: {
      action: 'manage',
      resource: 'Novel',
      breadcrumb: [
        {
          text: 'รายการบันทึก',
          to: { name: 'staff-novel-list' },
        },
        {
          text: 'ข้อมูลรายการ',
          active: true,
        },
      ],
    },
  },
  {
    path: '/staff/create',
    name: 'staff-create-new',
    component: () => import('@/views/staff/staff/StaffForm.vue'),
    meta: {
      action: 'create',
      resource: 'Staff',
    },
  },
  {
    path: '/staff/edit/:staffId',
    name: 'staff-edit',
    component: () => import('@/views/staff/staff/StaffForm.vue'),
    meta: {
      action: 'create',
      resource: 'Staff',
    },
  },
  {
    path: '/staff/list',
    name: 'staff-list',
    component: () => import('@/views/staff/staff/StaffList.vue'),
    meta: {
      action: 'list',
      resource: 'Staff',
    },
  },
]
