import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
let existingAbility = null
try {
  const userData = JSON.parse(localStorage.getItem('userData'))
  existingAbility = userData ? userData.ability : null
} catch (e) {
  console.error(e)
}

export default new Ability(existingAbility || initialAbility)
